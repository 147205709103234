<template lang="pug">
modal(v-slot='{ close }' size='xl' mode='drawer' :onBeforeClose='onBeforeClose' style='--modal-padding-bottom: 0px' @close='$emit("close")')
  inquiry(v-if='inquiryId !== "*"' ref='Inquiry' :inquiryId='inquiryId' :projectId='projectId' :isNewInquiry='isNewInquiry' @abort='$emit("close")' @close='close')
    template(#toolbarRight)
      btn(plain icon='x' @click='close')
  overlay(:visible='inquiryId == "*"')
</template>

<script setup lang="ts">
import { useInquiry, useInquiryActions } from '@/pages/inquiries/composables'
import { toRef } from 'vue'

const props = defineProps<{
  inquiryId: string
  projectId?: string
  isNewInquiry?: boolean
}>()
const { cancelCreateInquiry } = useInquiryActions()
const { isInquiryStatusDrafted, inquiry: currentInquiry } = useInquiry(toRef(props, 'inquiryId'))
const onBeforeClose = async () => {
  if (props.isNewInquiry && isInquiryStatusDrafted.value && !currentInquiry.value?.deleted) {
    return new Promise<boolean>((resolve) =>
      cancelCreateInquiry(
        props.inquiryId,
        () => resolve(true),
        () => resolve(true),
      ),
    )
  } else {
    return true
  }
}
</script>
